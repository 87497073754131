@import "./_variables.scss";

.overlay {
    align-items: center;
    backdrop-filter: blur(6px);
    background-color: rgba(1, 1, 1, 0.1);
    display: flex;
    justify-content: center;
}

.modal {
    bottom: 0;
    top: 0;
    @include media-up(md) {
        top: 22vh;
    }
}

.modal__content {
    a {
        margin: 0;
    }
}
