*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    font-family: "RijksoverheidSansWebText Regular", serif;
    font-size: $font-size;
    height: 100%;
    line-height: $line-height;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

input,
button {
    font-family: inherit;
    font-size: $font-size;
    line-height: $line-height;

    &:focus {
        outline: $focus-style;
    }
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
}

h3 {
    margin-top: 8px;
}

address {
    font-style: normal;
    font-weight: normal;
    margin-bottom: 16px;
}
