.footer {
    align-items: center;
    background: $color-rijksoverheid-grijs2;
    display: flex;
    font-size: $font-size-small;
    justify-content: center;
    padding: 5px 12px;
    z-index: 20;

    @include media-up(md) {
        justify-content: space-between;
    }

    &__meta {
        align-items: center;
        display: flex;

        & > :first-child {
            margin-right: $gutter;
        }
    }

    &__nav {
        display: none;

        @include media-up(md) {
            display: flex;
        }

        a {
            --link-color: #2b2b2b;
            margin-left: 20px;
        }
    }

    ::ng-deep .ggc-map-details-container {
        align-items: center;
        display: flex;
    }
}

::ng-deep .ggc-map-details-container,
::ng-deep .ol-scale-line {
    background: transparent;
}

::ng-deep .ol-scale-line .ol-scale-line-inner {
    border: 1px solid $color-rijksoverheid-grijs7;
    border-top: none;
    color: $color-rijksoverheid-grijs7;
    margin-top: 3px;
}
