@use "sass:math";
@import "./_variables.scss";

a {
    background-color: transparent;
    color: $color-rijksoverheid-donkerblauw;
    text-decoration: underline;
    span {
        display: inline-block;
        text-decoration: underline;
        & > * {
            padding-left: math.div($gutter, 2);
        }
    }
}

.pagination {
    align-items: baseline;
    border-radius: 0;
    display: flex;
    justify-content: center;
    list-style: none;
    margin-bottom: 1rem;
    padding-left: 0;
}

.page-link {
    background-color: #fff;
    border: 1px solid #cfdae7;
    color: #406a9e;
    display: block;
    height: 2rem;
    line-height: 1.5;
    margin-left: -1px;
    padding: 0 0;
    position: relative;
    text-align: center;
    width: 2rem;
}

.page-link:hover {
    background-color: #fff;
    border-color: #cfdae7;
    color: #00387d;
    text-decoration: none;
    z-index: 2;
}

.page-link:focus {
    box-shadow: 0 3px 6px #008dc9;
    outline: 0;
    z-index: 2;
}

.page-item:first-child .page-link {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 0;
}

.page-item:last-child .page-link {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.page-item.active .page-link {
    background-color: #406a9e;
    border-color: #406a9e;
    color: #fff;
    z-index: 1;
}

.page-item.disabled .page-link {
    background-color: #fff;
    border-color: #dee2e6;
    color: #6c757d;
    cursor: auto;
    pointer-events: none;
}

.pagination-lg .page-link {
    font-size: 1.25rem;
    line-height: 1.5;
    padding: 0.75rem 1.5rem;
}

.pagination-lg .page-item:first-child .page-link {
    border-bottom-left-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-bottom-right-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    font-size: 0.875rem;
    line-height: 1.375;
    padding: 0.25rem 0.5rem;
}

.pagination-sm .page-item:first-child .page-link {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.pagination-sm .page-item:last-child .page-link {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.page-navigation {
    color: #406a9e;
    font-weight: 700;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    white-space: nowrap;
}

.page-navigation:hover {
    color: #00387d;
}

.page-navigation-previous {
    padding-left: 0;
}

.page-navigation-next {
    padding-right: 0;
}

.pagination-separator {
    color: #406a9e;
    margin-left: 1rem;
    margin-right: 1rem;
}

.page-item.disabled .page-navigation {
    color: #d1d1d1;
}
