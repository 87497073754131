$checkbox-margin: 10px;
$checkbox-size: 24px;

.checkbox-label {
    align-items: center;
    display: flex;
    font-size: $font-size;
    line-height: $line-height;
    margin-right: $checkbox-margin;
    position: relative;

    &::before {
        background: $color-rijksoverheid-wit;
        border: 2px solid $color-rijksoverheid-grijs7;
        content: "";
        height: $checkbox-size;
        margin: $checkbox-margin;
        width: $checkbox-size;
    }

    .checkbox-input:checked + & {
        &::before {
            background: $color-rijksoverheid-donkerblauw;
            border-color: $color-rijksoverheid-donkerblauw;
        }

        &::after {
            background: $color-rijksoverheid-wit;
            content: "";
            height: $checkbox-size;
            left: $checkbox-margin;
            mask: url("^./assets/icons/check.svg") no-repeat center center;
            mask-size: 18px 18px;
            position: absolute;
            top: $checkbox-margin;
            width: $checkbox-size;
        }
    }
    .checkbox-input:focus + & {
        &::before {
            border-color: black;
            border-width: thick;
        }
    }
}

.checkbox-input {
    opacity: 0;
    position: absolute;
    z-index: 1;
}
