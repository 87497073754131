$color-rijksoverheid-hemelblauw: #007bc7;
$color-rijksoverheid-donkerblauw: #01689b;
$color-rijksoverheid-donkerblauw-tint-1: #cce0f1;
$color-rijksoverheid-donkerblauw-tint-2: #e5f0f9;
$color-rijksoverheid-groen: #39870c;
$color-rijksoverheid-wit: #ffffff;
$color-rijksoverheid-zwart: #000000;
$color-rijksoverheid-grijs2: #e6e6e6;
$color-rijksoverheid-grijs3: #cccccc;
$color-rijksoverheid-grijs7: #535353;

$button-size: 40px;
$dataset-height: 170px;
$logo-height-mobile: 60px;
$logo-height: 77px;
$logo-margin-bottom: 10px;
$toolbar-height: 46px;
$footer-height: 24px;

$focus-style: 2px dotted $color-rijksoverheid-zwart;
$font-size-small: 14px;
$font-size: 16px;
$line-height: 1.125;

$animation-duration: 0.2s;
$animation-bounce: cubic-bezier(0.175, 0.885, 0.32, 1.175);

$gutter: 8px;

$search-height: 50px;

$sidebar-min-width: 150px;
$sidebar-width: 420px;

$breakpoints: (
    "xs": 376px,
    "sm": 688px,
    "md": 992px,
    "lg": 1312px,
);
