dl {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.5;
    margin: $gutter ($gutter * -1) ($gutter * 2);
    width: calc(100% + (#{$gutter} * 2));

    dd,
    dt {
        margin: 0;
        padding: 0 $gutter;
        width: 50%;
    }

    dt {
        font-weight: bold;
    }
}
