.btn {
    align-items: stretch;
    background: $color-rijksoverheid-zwart;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: stretch;
    padding: 0;
    pointer-events: auto;

    &--primary {
        background: $color-rijksoverheid-donkerblauw;
        color: $color-rijksoverheid-wit;
        padding: $gutter * 1.5 $gutter * 2;
    }

    &--square {
        align-items: center;
        height: $button-size;
        justify-content: center;
        width: $button-size;
    }

    &--close {
        height: 24px;
        width: 24px;
    }

    &--transparent {
        background: transparent;
    }

    &--link {
        background: transparent;
        color: $color-rijksoverheid-zwart;
        text-decoration: underline;
    }

    &:focus {
        outline: 4px dotted $color-rijksoverheid-zwart;
    }

    &--image {
        align-items: stretch;
        border: 2px solid $color-rijksoverheid-donkerblauw;
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        height: 74px;
        justify-content: flex-start;
        overflow: hidden;
        width: 104px;
    }

    &__image {
        flex-shrink: 1;
        object-fit: cover;
        width: 100%;
    }

    &__label {
        background: $color-rijksoverheid-wit;
        flex-shrink: 0;
        font-size: $font-size-small;
        line-height: $line-height;
        padding: 4px 6px 6px;
        text-align: left;
    }

    &--hamburger {
        @include media-up(md) {
            display: none;
        }
    }

    &[disabled] {
        background: $color-rijksoverheid-grijs7;
        cursor: auto;
    }

    &.btn-link {
        align-items: center;
        background: none;
        border: none;
        color: $color-rijksoverheid-donkerblauw;
        cursor: pointer;
        text-decoration: none;

        &:focus,
        &:active {
            background-color: white;
            border: 0.19rem solid $color-rijksoverheid-hemelblauw;
            border-radius: 0.3rem;
            box-shadow: 0 0.063rem 0.375rem #008dc9;
            box-sizing: border-box;
            padding: calc(0.375rem - 0.19rem) calc(0.375rem - 0.19rem);
        }

        .btn-text {
            display: block;
            line-height: 1em;
            text-align: left;
            text-decoration: underline;
        }
    }
}
