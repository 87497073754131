@font-face {
    font-family: "RijksoverheidSansWebText Regular";
    font-weight: normal;
    src: url("./assets/fonts/ROsanswebtextregular.ttf");
}

@import "./styles/_variables.scss";
@import "./styles/_mixins.scss";

@import "./styles/_reset.scss";

@import "./styles/_btn.scss";
@import "./styles/_definition-list";
@import "./styles/_footer.scss";
@import "./styles/_input.scss";
@import "./styles/_icons.scss";
@import "./styles/_table.scss";
@import "./styles/_modal.scss";
@import "./styles/_sidebar.scss";
@import "./styles/_kadaster_huisstijl.scss";

@import "@angular/cdk/overlay-prebuilt.css";

.spacer {
    flex-grow: 1;
}

.ol-scale-line-inner {
    color: black !important;
}

.small-img {
    height: 20px;
    width: 20px;
}

.focus_map:focus-within {
    outline: black 4px dotted !important;
}
