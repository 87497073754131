@mixin icon($image, $color, $absolute) {
    &::after {
        background: $color;
        content: "";
        height: 100%;
        mask-image: url("^./assets/icons/" + $image + ".svg");
        mask-position: center center;
        mask-repeat: no-repeat;
        mask-size: contain;
        width: 100%;
    }

    @if $absolute {
        position: relative;

        &::after {
            bottom: 0;
            height: auto;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
        }
    }
}

@mixin dataset-animation() {
    transition: transform $animation-duration * 2 $animation-bounce;
}

@mixin media-up($name) {
    @media screen and (min-width: map-get($breakpoints, $name)) {
        @content;
    }
}

@mixin media($name) {
    @media screen and (max-width: map-get($breakpoints, $name)) {
        @content;
    }
}
@mixin media-up-and-orientation($name, $orientation) {
    @media screen and (min-width: map-get($breakpoints, $name)) and (orientation: $orientation) {
        @content;
    }
}
@mixin media-up-or-orientation($name, $orientation) {
    @media screen and (min-width: map-get($breakpoints, $name)), screen and (orientation: $orientation) {
        @content;
    }
}
