table {
    border-collapse: collapse;
    width: 100%;
}
th {
    text-align: left;
}
tbody tr:nth-child(odd) {
    background: $color-rijksoverheid-grijs2;
}

td {
    padding: 2px 0;
}
