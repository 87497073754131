.icon {
    align-items: stretch;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    width: 100%;

    &::before {
        background: $color-rijksoverheid-wit;
        content: "";
        flex-grow: 1;
        mask-position: left center;
        mask-repeat: no-repeat;
        mask-size: contain;
    }

    &--black {
        &::before {
            background: $color-rijksoverheid-zwart;
        }
    }

    &--search {
        &::before {
            mask-image: url("^./assets/icons/magnifier.svg");
        }
    }

    &--chevron {
        height: 50%;
        transition: transform $animation-duration ease;
        width: 50%;

        &::before {
            mask-image: url("^./assets/icons/chevron.svg");
            mask-position: center center;
        }

        &.icon--chevron-right {
            transform: rotate(90deg);
        }

        &.icon--chevron-down {
            transform: rotate(180deg);
        }

        &.icon--chevron-left {
            transform: rotate(270deg);
        }
    }

    &--hamburger {
        &::before {
            mask-image: url("^./assets/icons/hamburger.svg");
        }
    }

    &--layers {
        &::before {
            mask-image: url("^./assets/icons/layers.svg");
        }
    }

    &--close,
    &--remove {
        &::before {
            mask-image: url("^./assets/icons/cross.svg");
        }
    }
}
