@import "./_variables.scss";

.sidebar-block {
    margin-bottom: $gutter;
    &__header {
        align-items: center;
        background: $color-rijksoverheid-donkerblauw-tint-2;
        display: flex;
        padding: $gutter;

        h2 {
            flex-grow: 1;
            font-size: $font-size;
            font-weight: normal;
            margin: 0 $gutter;
            padding: 0;
        }
    }

    &__content {
        background: $color-rijksoverheid-wit;
        display: none;
        padding: $gutter;

        .sidebar-block--open & {
            display: block;
        }
    }
    &__suggestions {
        list-style-type: none;
        a {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
